<template>
    <section class="projects">
        <h2 class="projects__title">{{ $t('projects.title')}}</h2>
        <div class="projects__holder">
            <article class="projects__item" v-for="item in projects" :key="item.title[$i18n.locale]">
                <div class="wrapper">
                    <div class="projects__item-left">
                        <picture class="projects__item-image" :class="item.type === 'desktop' ? 'projects__item-image--desktop' : 'projects__item-image--mobile'"
                        >
                            <source type="image/webp" :srcset="item.imageWEBP" />
                            <source :srcset="item.image"  />
                            <img v-if="item.image" :src="item.image" :alt="item.title[$i18n.locale]" class="projects__item-image-actual" loading="lazy">
                        </picture>
                    </div>
                    <div class="projects__item-info">
                        <h3 class="projects__item-title">{{item.title[$i18n.locale]}}</h3>
                        <p class="projects__item-descr">{{item.description[$i18n.locale]}}</p>
                        <a :href="item.link" target="_blank" class="projects__item-btn" :title="$t('projects.visit.btn')" v-if="item.link !== ''">{{ $t('projects.visit.btn')}}</a>
                    </div>
                </div>
            </article>
        </div>
    </section>
</template>

<script>

export default {
    name: 'ProjectsSection',
    data() {
		return {
            projects: [
                {
                    title: {
                        bg: 'Surfgarage Арт уебсайт',
                        en: 'Surfgarage Art Website',
                    },
                    description: {
                        en: 'Website for artwork created by photographers and illustrators based in the Canary Islands who bring to life their travels, experiences, emotions and surf vibes through prints and posters. Site is developed using Vue 3 / Nuxt 3 as a Headless project, including Tailwind CSS and getting info from Contentful as headless CMS',
                        bg: 'Уебсайт за произведения на изкуството, създадени от фотографи и илюстратори, базирани на Канарските острови, които вдъхват живот на своите пътувания, преживявания, емоции и вибрации за сърф чрез отпечатъци и плакати. Сайтът е разработен с помощта на Vue 3 / Nuxt 3 като Headless проект, включително Tailwind CSS, а информацията идва от Contentful като headless CMS'
                    },
                    link: 'https://surfgarage.art/',
                    image: require('../assets/surfgarage.png'),
                    imageWEBP: require('../assets/surfgarage.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'EVenergy мобилно приложение',
                        en: 'EVenergy app',
                    },
                    description: {
                        en: 'This is mobile app for electric car owners. It tracks your charged energy, price paid for it and traveled distance, as well as trips. Then you are going to be able to see detailed statistic for avarage price and consumption per week/month/year and etc. It is created using React Native, Redux and Firebase as a storage.',
                        bg: 'Това е мобилно приложение за хора, които притежават електрически автомобили. С него имате възможност да следите заредената енергия, цената платена за нея, изминатото разстояние, както и отделните трипове, които правите. Можете да виждате детайлна статистика за средна цена на километър, консумация и още други за текушата седмица/месец/година. Приложението е написано чрез React Native, Redux и Firebase като основна база данни.'
                    },
                    link: 'https://play.google.com/store/apps/details?id=com.evenergy.com',
                    image: require('../assets/evenergy.png'),
                    imageWEBP: require('../assets/evenergy.webp'),
                    type: 'phone'
                },
                {
                    title: {
                        bg: 'Сватбен уебсайт',
                        en: 'Wedding Website',
                    },
                    description: {
                        en: 'This is website created specially for wedding. It could feature gallery, details about wedding, tables with guests, transportation info, timeline for wedding and etc.',
                        bg: 'Този уебсайт е направен специално за сватба. Той може да включва галерия, детайли за сватбата, списък с гостите и разпределението им по масите, полезна информация за таксита за придвижване до ресторанта, както и програма на сватбения ден.'
                    },
                    link: 'https://www.wedding-demo.givanov.eu/',
                    image: require('../assets/weddingwebsite.png'),
                    imageWEBP: require('../assets/weddingwebsite.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'Сватбен Органайзер',
                        en: 'My Wedding',
                    },
                    description: {
                        en: 'Mobile application for organising your Wedding. Here you will find wedding timer, task lists, budget for the spending, invitations for best man and etc. This is Mobile Hybrid app which is based on Vue JS, ES6 and Cordova, using serveless Firebase services.',
                        bg: 'С приложението за организиране на сватба вие имате на едно място: таймер до сватбата, следване на сватбен списък със задачи, водене на бюджет за сватбата, водене на списък с гости, водене на списък за моминско парти, водене на списък за ергенско парти, покани за кумуве. Това е мобилно хибридно приложението написано с помощта на Vue JS, ES6 и Cordova, както и на гугъл услугите като Firebase. '
                    },
                    link: 'https://play.google.com/store/apps/details?id=com.mywedding.gmdev',
                    image: require('../assets/mywedding.png'),
                    imageWEBP: require('../assets/mywedding.webp'),
                    type: 'phone'
                },
                {
                    title: {
                        bg: 'Електронен Магазин Krushka',
                        en: 'Online Store Krushka',
                    },
                    description: {
                        en: 'Online store for selling and managing of electronic components, lights, switch-breaker and etc while administrator is able to maintain huge amount of over 10 000 products and more than 200 categories. This project is based on WordPress plus WooCommerce.',
                        bg: 'Електронен магазин за продажба и мениджиране на електронни компонент, крушки, предпазители и т.н. като се поддържа огромно количество от над 10 000 продукта и над 200 категории. Базиран на Wordpress заедно с WooCommerce.'
                    },
                    link: 'https://krushka.bg/',
                    image: require('../assets/krushka.png'),
                    imageWEBP: require('../assets/krushka.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'Корпоративен рекламен уебсайт',
                        en: 'Company advertisement website',
                    },
                    description: {
                        en: 'Front end development of company advertising website for company GDStyles. It is integrated in custom back end system, all rights reserved to GDStyles',
                        bg: 'Фронт Енд изработка на корпоративен рекламен сайт за фирма GDStyles. Интегриран в къстъм бекенд система, всички права са запазени на GDStyles'
                    },
                    link: 'https://www.chimatech.bg/',
                    image: require('../assets/chimatech.png'),
                    imageWEBP: require('../assets/chimatech.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'Хотелски сайт',
                        en: 'Hotel Website',
                    },
                    description: {
                        en: 'Front end development of hotel advertising website for company GDStyles. It is integrated in custom back end system, all rights reserved to GDStyles',
                        bg: 'Фронт Енд изработка на хотелски рекламен сайт за фирма GDStyles. Интегриран в къстъм бекенд система, всички права са запазени на GDStyles'
                    },
                    link: 'https://klukarnika.com/',
                    image: require('../assets/kluikarnika.png'),
                    imageWEBP: require('../assets/kluikarnika.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'Хотелски уебсайт',
                        en: 'Hotel website',
                    },
                    description: {
                        en: 'Front end development of hotel website for company GDStyles. It is integrated in custom back end system, all rights reserved to GDStyles',
                        bg: 'Фронт Енд изработка на хотелски сайт за фирма GDStyles. Интегриран в къстъм бекенд система, всички права са запазени на GDStyles'
                    },
                    link: 'https://www.sofiapalacehotel.com/bg',
                    image: require('../assets/sofiapalace.png'),
                    imageWEBP: require('../assets/sofiapalace.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'Органайзер на задачи',
                        en: 'Task Organiser',
                    },
                    description: {
                        en: 'Task Organiser is web application which lets user organise their tasks into list and categories. You can add title, description, end dates, mark task as complited, delete tasks and many more. It is developed user Angular JS, HTML5, CSS3',
                        bg: 'Органайзера на задачи е уеб приложение, което позволява на потребителите да въвеждат списък със задачи за рaзпределни в категории. Всяка задача има заглавие, описание и крайна дата. Възможно е маркирането за задачата като вече изпълнена както и изтриването на всички изпълнени вече задачи.'
                    },
                    link: 'https://www.taskorganiser.givanov.eu/',
                    image: require('../assets/taskorganiser.png'),
                    imageWEBP: require('../assets/taskorganiser.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'Сайт Сватбен Органайзер',
                        en: 'Wedding Organiser Website',
                    },
                    description: {
                        en: 'This is presentational website for mobile hybrid application My Wedding',
                        bg: 'Това е презентационният уебсайт за мобилното хибридно приложение Сватбен Органайзер'
                    },
                    link: 'https://www.svatben-organaizer.givanov.eu/',
                    image: require('../assets/svatben-1.png'),
                    imageWEBP: require('../assets/svatben-1.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'No Half Time',
                        en: 'No Half Time',
                    },
                    description: {
                        bg: 'Това е презентационен сайт за спортно мобилно приложение, при което администратора на сайта има пълен контрол на текста. Всичко е направено на базата на специфично изработена Wordpress тема спрямо дизайна на клиента, без да се използват плъгини.',
                        en: 'This is presentation website for Sport app and administrator has full control over text on it. It is entirely custom Wordpress theme created with Custom Post Types and no plugis'
                    },
                    link: 'http://nohalftime.givanov.eu/',
                    image: require('../assets/nohalftime.png'),
                    imageWEBP: require('../assets/nohalftime.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'Ride Drive',
                        en: 'Ride Drive',
                    },
                    description: {
                        en: 'Buy and sell cars using platform RiteDrive',
                        bg: 'Платформа за покупко продажба на автомобили'
                    },
                    link: 'https://www.givanov.eu/projects/ritedrive/public/landing.html',
                    image: require('../assets/ritedrive.png'),
                    imageWEBP: require('../assets/ritedrive.webp'),
                    type: 'desktop'
                },
                {
                    title: {
                        bg: 'Карандила',
                        en: 'Karandila',
                    },
                    description: {
                        en: 'Mobile Hybrid App for fans of mountains.',
                        bg: 'Мобилно хибридно приложение писано на Vue JS за любители планинари.'
                    },
                    link: '',
                    image: require('../assets/karandila.png'),
                    imageWEBP: require('../assets/karandila.webp'),
                    type: 'mobile'
                },
                {
                    title: {
                        bg: 'Playbook',
                        en: 'Playbook',
                    },
                    description: {
                        en: 'Online full featured betting platforms for different sports.',
                        bg: 'Напълно функционална онлайн платформа за залагания в различни видове спорт.'
                    },
                    link: 'https://www.givanov.eu/projects/playbook/',
                    image: require('../assets/playbook.png'),
                    imageWEBP: require('../assets/playbook.webp'),
                    type: 'desktop'
                },
               
            ]
		}
    },
}
</script>

<style lang="scss">
    .projects {
        position: relative;
        padding: 3.5rem 0;
        content-visibility: auto;
        z-index: 1;

        @media (min-width: 768px) {
            padding: 5rem 0;
        }

        &__title {
            text-transform: uppercase;
            text-align: center;
            color: var(--main-akcent-color);
        }

        &__holder {
            margin-top: 30px;
        }

        &__item {
            border-bottom: 1px solid rgba(0,0,0,.1);
            padding-top: 40px;
            padding-bottom: 40px;
            text-align: center;

            @media (min-width: 768px) {
                padding-top: 80px;
                padding-bottom: 80px;
                text-align: left;

                .wrapper {
                    display: flex;
                    align-items: center;
                }
            }

            &-info {
                flex: 1 1 50%;

                @media (min-width: 768px) {
                    padding-left: 30px;
                }
            }

            &-left {
                flex: 1 1 50%;
                text-align: center;
            }

            &-image {
                position: relative;
                display: inline-block;
                margin: 0;
                overflow: hidden;

                &--mobile img {
                    max-height: 500px;
                }
            }

            &-title {
                font-size: 1.75rem;
                font-weight: 700;
                margin-bottom: 20px;
            }   

            &-descr {
                color: var(--project-description-color);
                margin-bottom: 30px;
            }

            &-btn {
                display: inline-block;
                padding: 12px 30px;
                border-radius: 30px;
                border: 2px solid var(--main-akcent-color);
                color: var(--main-akcent-color);
                font-size: 1rem;
                line-height: 1.5;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

                @media (min-width: 1024px) {
                    &:hover, &:focus {
                        background-color: var(--main-akcent-color);
                        outline: none;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>