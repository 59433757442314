<template>
    <section class="about">
        <div class="wrapper">
            <h2 class="about__title">{{ $t('about.title') }}</h2>
            <div class="about__holder">
                <article class="about__item">
                    <h3 class="about__item-title">{{ $t('about.who.title') }}</h3>
                    <div class="about__item-description" v-html="$t('about.who.description')"></div>
                </article>
                <article class="about__item">
                    <h3 class="about__item-title">{{ $t('about.what.title') }}</h3>
                    <div class="about__item-description" v-html="$t('about.what.description')"></div>
                </article>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'AboutSection',
}
</script>

<style lang="scss">
    %pseudo-elements-common {
        position: absolute;
        content: " ";
        right: 0;
        left: 0;
        background-position: center top;
        background-size: cover;
        z-index: 0;
    }
    .about {
        position: relative;
        background-color: var(--main-akcent-color);
        padding: 3.5rem 0 12.5rem 0;

        @media (min-width: 768px) {
            padding-top: 5rem;
        }
        color: #fff;

        &:after {
            @extend %pseudo-elements-common;
            bottom: -9.375rem;
            opacity: 1;
            height: 300px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNjc5IDE5OC42Jz48cGF0aCBkPSdNODM5LjUgOTYuN0M0ODUuMyA5Ni43IDE3NC41IDU3LjkgMCAwdjE5OC42aDE2NzlWMGMtMTc0LjUgCTU3LjktNDg1LjMgOTYuNy04MzkuNSA5Ni43eicgZmlsbD0nI2ZmZicvPjwvc3ZnPg==)
        }

        &:before {
            @extend %pseudo-elements-common;
            opacity: 1;
            bottom: 0;
            left: 0;
            height: 14.375rem;
            opacity: .1;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNDQwIDMyMCc+PHBhdGggZmlsbD0nI2ZmZmZmZicgZmlsbC1vcGFjaXR5PScwLjc1JyBkPSdNMCwzMkw2MCw1My4zQzEyMCw3NSwyNDAsMTE3LDM2MCwxNDRDNDgwLDE3MSw2MDAsMTgxLDcyMCwxNjBDODQwLDEzOSw5NjAsODUsMTA4MCw4MEMxMjAwLDc1LDEzMjAsMTE3LDEzODAsMTM4LjdMMTQ0MCwxNjBMMTQ0MCwzMjBMMTM4MCwzMjBDMTMyMCwzMjAsMTIwMCwzMjAsMTA4MCwzMjBDOTYwLDMyMCw4NDAsMzIwLDcyMCwzMjBDNjAwLDMyMCw0ODAsMzIwLDM2MCwzMjBDMjQwLDMyMCwxMjAsMzIwLDYwLDMyMEwwLDMyMFonPjwvcGF0aD48L3N2Zz4=)
        }

        &__title {
            text-align: center;
            text-transform: uppercase;
        }

        &__holder {
            &:after {
                @extend %pseudo-elements-common;
                bottom: -4.375rem;
                opacity: .1;
                height: 330px;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNDQwIDMyMCc+PHBhdGggZmlsbD0nI2ZmZmZmZicgZmlsbC1vcGFjaXR5PScxJyBkPSdNMCwyMjRMNjAsMjAyLjdDMTIwLDE4MSwyNDAsMTM5LDM2MCwxMjhDNDgwLDExNyw2MDAsMTM5LDcyMCwxNDkuM0M4NDAsMTYwLDk2MCwxNjAsMTA4MCwxMzguN0MxMjAwLDExNywxMzIwLDc1LDEzODAsNTMuM0wxNDQwLDMyTDE0NDAsMzIwTDEzODAsMzIwQzEzMjAsMzIwLDEyMDAsMzIwLDEwODAsMzIwQzk2MCwzMjAsODQwLDMyMCw3MjAsMzIwQzYwMCwzMjAsNDgwLDMyMCwzNjAsMzIwQzI0MCwzMjAsMTIwLDMyMCw2MCwzMjBMMCwzMjBaJz48L3BhdGg+PC9zdmc+)
            }
        }

        &__item {
            flex: 0 1 45%;

            &-title {
                text-transform: uppercase;
            }

            &-description {
                a {
                    text-decoration: underline;
                }
            }
        }
    }
</style>