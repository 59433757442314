<template>
    <section class="contact">
        <div class="wrapper">
            <div class="contact__headline">
                <h4 class="contact__title">{{ $t('contact.title')}}</h4>
                <div class="contact__info">{{ $t('contact.info')}}</div>
                <button :class="['contact__btn', { 'active' : formActive }]" @click="toggleContactForm()">{{ $t('contact.btn')}}</button>
            </div>
            <form name="hire" method="post" class="contact__form form" @submit.prevent="handleSubmitForm" v-if="formActive">
				<label class="form__row" data-hover="fname" for="fname">
					<input type="text" data-label="First Name" :placeholder="$t('contact.form.first.name')" name="fname" id="fname" class="form__input" required>
					<input type="text" data-label="Last Name" :placeholder="$t('contact.form.last.name')" name="lname" id="lname" class="form__input" required>
				</label>
				<label class="form__row" data-hover="email" for="email">
					<input type="email" data-label="Email Address" :placeholder="$t('contact.form.email')" name="email" id="email" class="form__input" required>
					<input type="text" data-label="Topic" :placeholder="$t('contact.form.topic')" name="topic" class="form__input" id="topic">
				</label>
				<label class="form__row details" for="details">
					<textarea data-label="More Details" name="details" id="details" :placeholder="$t('contact.form.details')" class="form__input form__input--textarea" required></textarea>
				</label>
				<div class="form__row buttons">
					<input type="submit" :value="$t('contact.btn.submit')" name="submitForm" class="form__btn">
				</div>
			</form>
            <div class="contact__submit-message js-submit-message">
                <h2 v-html="$t('contact.submit.success')"></h2>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'ContactSection',
    data() {
		return {
			formActive: false
		}
	},
	methods: {
		toggleContactForm() {
			this.formActive = !this.formActive;
        },
        handleSubmitForm(e) {
            const form = e.target;
            const fname = form.querySelector('[name="fname"]').value,
            lname = form.querySelector('[name="lname"]').value,
            email = form.querySelector('[name="email"]').value,
            details = form.querySelector('[name="details"]').value,
            submitMessage = document.querySelector('.js-submit-message');
            const _this = this;

            if ( !fname || !lname || !email || !details ) return;

            let xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function() {
                if (this.readyState == 4 && this.status == 200) {
                    _this.formActive = false
                    submitMessage.classList.add('active');
                    setTimeout(function() {
                        form.reset()
                        submitMessage.classList.remove('active');
                    }, 5000);
                }
            };
            xhttp.open("POST", "../../send_form_email.php", true);
            xhttp.send(new FormData(form));
        }
	}
}
</script>

<style lang="scss">
    %btn-styles {
        border: 2px solid #5199e4;
        border-radius: 30px;
        padding: 0.8rem 1.5rem;
        background: transparent;
        color: #fff;
        content-visibility: auto;
        font-size: 1.4rem;
        cursor: pointer;
        transition: all .3s ease;

        @media (min-width: 1024px) {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
        }

        @media (min-width: 1024px) {
            &:hover, &.active, &:focus {
                background: #5199e4;
            }
        }
    }

    .contact {
        position: relative;
        padding: 4rem 1rem;
        background-color: var(--main-akcent-color);
        color: #fff;
        margin-top: 9rem;
        z-index: 2;

        .wrapper {
            position: relative;
            margin-top: -9rem;
            background-color: #293347;
            border-radius: 12px;
            box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px var(--main-text-color);
            padding: 3.5rem 1.25rem;

            @media (min-width: 768px) {
                max-width: 1200px;
            }
        }

        &__headline {
            text-align: center;

            @media (min-width: 768px) {
                display: flex;
                align-items: center;
                justify-content: space-around;

            }
        }

        &__title {
            font-size: 2rem;
            font-weight: 700;
            margin: 0;
        }

        &__info {
            text-align: center;
            margin: 20px 0;
            padding: 0 1rem;

            @media (min-width: 768px) {
                margin: 0;
                max-width: 400px;
            }
        }

        &__btn {
            @extend %btn-styles;
        }

        &__form {
            margin-top: 3rem;
        }

        &__submit-message {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 12px;
            background-color: var(--main-text-color);
            display: none;
            align-items: center;
            justify-content: center;

            &.active {
                display: flex;
            }

            strong {
                color: var(--main-akcent-color);
            }
        }
    }

    .form {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }

        &__input {
            height: 40px;
            width: 95%;
            border-radius: 5px;
            border: none;
            padding: 5px 10px;

            & + & {
                margin-left: 5%;
            }

            &--textarea {
                width: 100%;
                height: 130px;
            }
        }

        &__btn {
            @extend %btn-styles;
        }
    }
</style>