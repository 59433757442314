<template>
  <div class="main-menu">
    <button :class="['main-menu__button', { 'active' : isMenuActive }]"  @click="toggleMenuState" :title="$t('mainmenu.btn')" :aria-label="$t('mainmenu.btn')"><span></span></button>
    <nav :class="['main-menu__nav', { 'active' : isMenuActive }]">
        <ul class="main-menu__list">
            <li class="main-menu__list-item" @click="scrollEl('.hero')"><button>{{ $t('mainmenu.home') }}</button></li>
            <li class="main-menu__list-item" @click="scrollEl('.about')"><button>{{ $t('mainmenu.about') }}</button></li>
            <li class="main-menu__list-item" @click="scrollEl('.skills')"><button>{{ $t('mainmenu.skills') }}</button></li>
            <li class="main-menu__list-item" @click="scrollEl('.projects')"><button>{{ $t('mainmenu.projects') }}</button></li>
            <li class="main-menu__list-item" @click="scrollEl('.contact')"><button>{{ $t('mainmenu.contact') }}</button></li>
            <li class="main-menu__list-item">
                <button @click="changeLocale('en')">{{ $t('mainmenu.en') }}</button>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
                <path style="fill:#FFFFFF;" d="M496,369.6c0,27.2-21.6,46.4-48,46.4H48c-26.4,0-48-19.2-48-46.4V126.4C0,99.2,21.6,80,48,80h400
                    c26.4,0,48,19.2,48,46.4V369.6z"/>
                <path style="fill:#E9F3F4;" d="M48,80h400c26.4,0,48,19.2,48,46.4v243.2c0,27.2-22.4,46.4-48.8,46.4"/>
                <g>
                    <path style="fill:#D9ECED;" d="M496,368.8c0,27.2-21.6,47.2-48,47.2H48c-26.4,0-48-20.8-48-48"/>
                    <path style="fill:#D9ECED;" d="M48,80h400c26.4,0,48,19.2,48,46.4V216"/>
                </g>
                <polygon style="fill:#D32027;" points="496,216 280,216 280,80 216,80 216,216 0,216 0,280 216,280 216,416 280,416 280,280 
                    496,280 "/>
                <g>
                    <rect x="216" y="368" style="fill:#AA0015;" width="64" height="48"/>
                    <polygon style="fill:#AA0015;" points="280,80 216,80 216,216 210.4,216 285.6,280 496,280 496,216 280,216 	"/>
                </g>
                </svg>
            </li>
            <li class="main-menu__list-item">
                <button @click="changeLocale('bg')">{{ $t('mainmenu.bg') }}</button>
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
                <path style="fill:#DB2727;" d="M0,304v65.6C0,396.8,21.6,416,48,416h400c26.4,0,48-19.2,48-46.4V304H0z"/>
                <path style="fill:#F3F4F5;" d="M448,80H48C21.6,80,0,99.2,0,126.4V192h496v-65.6C496,99.2,474.4,80,448,80z"/>
                <rect y="192" style="fill:#589F58;" width="496" height="112"/>
                <path style="fill:#C61A1A;" d="M446.4,416c26.4,0,49.6-19.2,49.6-46.4V304H315.2L446.4,416z"/>
                <path style="fill:#D7DEE2;" d="M448,80H48l132,112h316v-65.6C496,99.2,474.4,80,448,80z"/>
                <polygon style="fill:#4D8C4D;" points="315.2,304 496,304 496,192 180,192 "/>
                <path style="fill:#BEC8CC;" d="M448,80H48l367.2,112H496v-65.6C496,99.2,474.4,80,448,80z"/>
                <polygon style="fill:#3D7A3D;" points="496,214.4 496,192 414.4,192 "/>
                <path style="fill:#AF0E0E;" d="M496,368.8c0,29.6-21.6,47.2-48,47.2H48c-26.4,0-48-20.8-48-48"/>
                </svg>
            </li>
        </ul>
    </nav>
  </div>
</template>

<script>
import {smoothScroll} from '../App'
import i18n from '../i18n'

export default {
	name: 'MenuBlock',
	data() {
		return {
			isMenuActive: false
		}
	},
	methods: {
		toggleMenuState() {
			this.isMenuActive = !this.isMenuActive;
		},
		changeLocale(lang) {
            i18n.locale = lang
            
            localStorage.getItem('userChangedLanguage') ? null : localStorage.setItem('userChangedLanguage', true)
		},
		scrollEl(target) {
            smoothScroll(target)
            this.isMenuActive = false
		}
	}
}
</script>

<style scoped lang="scss">
    .main-menu {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 101;

        &__button {
            position: relative;
            width: 45px;
            height: 45px;
            background-color: var(--main-akcent-color);
            border-radius: 50%;
            border: none;
            box-shadow: #fff 0px 0px 5px;
            transition: box-shadow .3s ease-in;
            cursor: pointer;
            z-index: 101;

            @media (min-width: 767px) {
                width: 55px;
                height: 55px;
            }

            @media (min-width: 1024px) {
                &:hover, &:focus {
                    box-shadow: var(--main-akcent-color) 0px 0px 5px;
                }
            }

            &.active span {
                background: none;
                transition-delay: 0s, 0.3s;

                &:before {
                    transform: rotate(45deg);
                    top: 0;
                    transition-delay: 0s, 0.3s;
                }

                &:after {
                    transform: rotate(-45deg);
                    bottom: 0;
                    transition-delay: 0s, 0.3s;
                }
            }

            span {
                display: block;
                position: absolute;
                width: 50%;
                top: 50%;
                left: 50%;
                height: 3px;
                transform: translate(-50%, -50%);
                transition: background 0s 0.3s;
                background: #fff;

                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background-color: #fff;
                    transition-duration: 0.3s, 0.3s;
                    transition-delay: 0.3s, 0s;
                }

                &:before {
                    top: -8px;
                    transition-property: top, transform;
                }

                &:after {
                    bottom: -8px;
                    transition-property: bottom, transform;
                }
            }
        }

        &__nav {
            position: fixed;
            z-index: 100;
            background-color: var(--main-akcent-color);
            transition: width 0.3s, height 0.3s;
            width: 0;
            height: 0;
            font-size: 1.5em;
            top: 0px;
            right: 0px;
            transform-origin: 100% 0%;
            overflow: hidden;
            visibility: hidden;

            @media (min-width: 768px) {
                border-bottom-left-radius: 500px;
            }

            &.active {
                width: 100%;
                height: 100%;
                visibility: visible;
                animation: anim-jelly 0.8s linear forwards;

                @media (min-width: 768px) {
                    width: 500px;
                    height: 500px;
                }

                .main-menu__list {
                    opacity: 1;
                }
            }
        }

        &__list {
            padding: 60px 0;
            margin: 0;
            text-align: center;
            list-style-type: none;
            opacity: 0;
            transition: opacity 0.3s 0.3s;

            @media (min-width: 768px) {
                padding: 60px 0 0 20%;
            }

            &-item {
                color: #fff;
                text-transform: uppercase;
                margin: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    position: relative;
                    cursor: pointer;
                    background: none;
                    border: none;
                    color: inherit;
                    font-size: inherit;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 50%;
                        right: 50%;
                        bottom: -2px;
                        background: #fff;
                        height: 2px;
                        transition-property: left, right;
                        transition-duration: 0.4s;
                        transition-timing-function: ease-out;
                        display: none;
                    }

                     @media (min-width: 1024px) {
                        &:before {
                            display: block;
                        }

                        &:hover,
                        &:focus {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                        }
                    }
                }

                svg {
                    width: 40px;
                    height: 25px;
                }
            }
        }
    }

    @keyframes anim-jelly {
        0% {
            transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        3.333333% {
            transform: matrix3d(0.32778, 0, 0, 0, 0, 0.32778, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        6.666667% {
            transform: matrix3d(0.69875, 0, 0, 0, 0, 0.69875, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        10% {
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        13.333333% {
            transform: matrix3d(1.1815, 0, 0, 0, 0, 1.1815, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        16.666667% {
            transform: matrix3d(1.24402, 0, 0, 0, 0, 1.24402, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        20% {
            transform: matrix3d(1.21871, 0, 0, 0, 0, 1.21871, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        23.333333% {
            transform: matrix3d(1.14702, 0, 0, 0, 0, 1.14702, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        26.666667% {
            transform: matrix3d(1.06589, 0, 0, 0, 0, 1.06589, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        30% {
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        33.333333% {
            transform: matrix3d(0.9603, 0, 0, 0, 0, 0.9603, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        36.666667% {
            transform: matrix3d(0.94663, 0, 0, 0, 0, 0.94663, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        40% {
            transform: matrix3d(0.95217, 0, 0, 0, 0, 0.95217, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        43.333333% {
            transform: matrix3d(0.96784, 0, 0, 0, 0, 0.96784, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        46.666667% {
            transform: matrix3d(0.98559, 0, 0, 0, 0, 0.98559, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        50% {
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        53.333333% {
            transform: matrix3d(1.00868, 0, 0, 0, 0, 1.00868, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        56.666667% {
            transform: matrix3d(1.01167, 0, 0, 0, 0, 1.01167, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        60% {
            transform: matrix3d(1.01046, 0, 0, 0, 0, 1.01046, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        63.333333% {
            transform: matrix3d(1.00703, 0, 0, 0, 0, 1.00703, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        66.666667% {
            transform: matrix3d(1.00315, 0, 0, 0, 0, 1.00315, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        70% {
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        73.333333% {
            transform: matrix3d(0.9981, 0, 0, 0, 0, 0.9981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        76.666667% {
            transform: matrix3d(0.99745, 0, 0, 0, 0, 0.99745, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        80% {
            transform: matrix3d(0.99771, 0, 0, 0, 0, 0.99771, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        83.333333% {
            transform: matrix3d(0.99846, 0, 0, 0, 0, 0.99846, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        86.666667% {
            transform: matrix3d(0.99931, 0, 0, 0, 0, 0.99931, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        90% {
            -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        93.333333% {
            transform: matrix3d(1.00042, 0, 0, 0, 0, 1.00042, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        96.666667% {
            transform: matrix3d(1.00056, 0, 0, 0, 0, 1.00056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }

        100% {
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        }
    }
</style>
