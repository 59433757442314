import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import i18n from './i18n'

Vue.config.productionTip = false

new Vue({
	i18n,
	render: h => h(App),
	mounted() {
		if (!localStorage.getItem('userChangedLanguage')) {
			fetch('https://get.geojs.io/v1/ip/country.json')
			.then((resp) => resp.json())
			.then(function(resp) {
				i18n.locale = resp.country === 'BG' ? 'bg' : 'en'
			})
			.catch(function(error) {
				console.error(error);
				i18n.locale = 'en'
			});  
		}
			
		document.querySelector('.js-loader').classList.add('hidden');
	}
}).$mount('#app')