<template>
    <section class="brands">
        <!-- <div class="wrapper"> -->
            <h2 class="brands__title">{{ $t('brands.title') }}</h2>
            <div class="brands__holder">
                <article class="brands__item" v-for="brand in brands" :key="brand.title">
                    <img :src="brand.image" :style="brand.styles" />
                </article>
            </div>
        <!-- </div> -->
    </section>
</template>

<script>

export default {
    name: 'BrandsSection',
    data() {
		return {
            brands: [
                {
                    title: 'The North Face',
                    image: require('../assets/brands/tnf.png'),
                },
                {
                    title: 'Parts Town',
                    image: require('../assets/brands/pt.png'),
                },
                {
                    title: 'LifeFitness',
                    image: require('../assets/brands/lfn.png'),
                },
                {
                    title: 'sOliver',
                    image: require('../assets/brands/soliver.png'),
                },
                {
                    title: 'Yugo',
                    image: require('../assets/brands/yugo.png'),
                    styles: 'max-width: 75%; max-height: 120px;'
                },
                {
                    title: 'SpaceNk',
                    image: require('../assets/brands/spacenk.png'),
                },
            ]
        }
    }
}
</script>

<style lang="scss">
    .brands {
        margin: 60px 0 220px 0;

        @media (max-width: 768px) {
            margin-bottom: 160px;
        }

        &__title {
            text-transform: uppercase;
            text-align: center;
            color: var(--main-akcent-color);
            margin-bottom: 40px;
        }

        &__item {
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-basis: 15%;

            @media (max-width: 1024px) {
                flex-basis: 30%;
            }

            @media (max-width: 768px) {
                flex-basis: 45%;
            }

            img {
                max-height: 100%;

                @media (max-width: 768px) {
                    max-width: 150px;
                }
            }
        }

        &__holder {
            display: flex;
            align-items: center;
            gap: 2rem;

            @media (max-width: 1024px) {
                flex-wrap: wrap;
                justify-content: center;
                gap: 3rem;
            }
        }
    }
</style>