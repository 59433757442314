<template>
  <div id="app">
    <MainMenu />
    <HeroSection />
    <AboutSection />
    <SkillsSection />
    <ProjectsSection />
    <BrandsSection />
    <ContactSection />
    <Footer />
  </div>
</template>

<script>
import MainMenu from './components/Menu.vue'
import HeroSection from './components/Hero.vue'
import AboutSection from './components/About.vue'
import SkillsSection from './components/Skills.vue'
import ProjectsSection from './components/Projects.vue'
import ContactSection from './components/Contact.vue'
import Footer from './components/Footer.vue'
import BrandsSection from './components/Brands.vue'

export function smoothScroll(elTarget) {
	window.scrollTo({ 
		top: document.querySelector(elTarget).offsetTop, 
		behavior: 'smooth' 
	})
}

export default {
  name: 'app',
  components: {
    MainMenu,
    HeroSection,
    AboutSection,
    SkillsSection,
    ProjectsSection,
    ContactSection,
    BrandsSection,
    Footer
  }
}
</script>

<style lang="scss">
  *  {
    box-sizing: border-box;
    letter-spacing: 0.9px;
  }

  :root {
    --main-akcent-color: #5199e4;
    --main-text-color: #293347;
    --secondary-text-color: #fff;
    --main-background-color: #fff;
    --project-description-color: #808080;

    @media (prefers-color-scheme: dark) {
      --main-background-color: #5199e4;
      --main-akcent-color: #293347;
      --main-text-color: #fff;
      --secondary-text-color: #fff;
      --project-description-color: #fff;
    }
  }

  html {
    font-size: 12px;

    @media (min-width: 420px) {
      font-size: 13px;
    }

    @media (min-width: 640px) {
      font-size: 14px;
    }

    @media (min-width: 767px) {
      font-size: 15px;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
    }

    transition: color .3s ease, background-color .3s ease;
  }
  body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    color: var(--main-text-color);
    background-color: var(--main-background-color);
  }
  button {
    outline: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  img {
    max-width: 100%;
  }
  .wrapper {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
</style>
